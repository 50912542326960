import React from 'react';
import { NavLink } from 'react-router-dom';
import NhgLogo from '../../resources/img/nhg.png'
import MaastrichtLogo from '../../resources/img/opleidingspraktijk_maastricht.png'
import './footer.scss';

export const Footer = () => (
    <footer className="footer">
      <div className="content flex">
        <div className="footer-wrapper footer-wrapper-top">
          <div className="footer-item">
            <h4>Adres Dorst</h4>
            <ul className="footer-item-list">
              <li className="footer-item-list-item">
                Taxusstraat 49
              </li>
              <li className="footer-item-list-item">
                4849 AC Dorst
              </li>
            </ul>
          </div>
          <div className="footer-item">
            <h4>Adres Bavel</h4>
            <ul className="footer-item-list">
              <li className="footer-item-list-item">
                Deken Dr. Dirckxweg 2
              </li>
              <li className="footer-item-list-item">
                4854 AB Bavel
              </li>
            </ul>
          </div>
          <div className="footer-item">
            <ul className="footer-item-list">
              <li className="footer-item-list-item">
                <NavLink className="footer-item-list-link" to="/no-show-beleid">No-show beleid</NavLink>
              </li>
              <li className="footer-item-list-item">
                <NavLink className="footer-item-list-link" to="/privacyreglement">Privacyreglement</NavLink>
              </li>
              <li className="footer-item-list-item">
                <NavLink className="footer-item-list-link" to="/klachtenregeling">Klachtenregeling</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-wrapper">
          <div className="footer-item logo">
            <img src={NhgLogo}
                 className="lazy lazyloaded"
                 alt="NHG Accreditering"/>
            <img src={MaastrichtLogo}
                 className="lazy lazyloaded"
                 alt="Opleidingspraktijk Universiteit Maastricht"/>
          </div>
        </div>
        <div className="footer-wrapper">
          <div className="footer-item center copyright">
            &copy; 2022 Huisartsenpraktijk van Beijsterveldt
          </div>
        </div>
      </div>
    </footer>
);