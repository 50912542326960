import { Photo } from './photo';

export class Employee {

  constructor(readonly name: string,
              readonly functionTitle: string,
              readonly description: string[],
              readonly photo: Photo,
              readonly workingHours?: string) {
  }

}