import { NewsItem } from "../domain/news-item";
import { Photo } from "../domain/photo";
import Stoptober from "../resources/img/news/stoptober.jpg";
import Phone from "../resources/img/news/phone.png";

const createNewsItem = (title: string,
                        date: Date,
                        content: string,
                        picture?: Photo): NewsItem => {
  return new NewsItem(title, date, content, picture);
};

export const newsItems = [
  createNewsItem("Nieuwe telefooncentrale", new Date("2023-05-22"),
      `Per 25 mei krijgen wij een nieuwe telefooncentrale.  Voor u verandert er eigenlijk niets.
      \n Ons  telefoonnummer 0161 412100 en het keuzemenu blijven hetzelfde: 
      \n- Optie 1: alleen voor spoedgevallen / for emergencies only
      \n- Optie 2: herhaalreceptenlijn
      \n- Optie 3: assistente
      \n- Optie 4: intercollegiaal overleg`,
      new Photo(Phone, "Telefooncentrale")),
  createNewsItem("Lange klachten na Corona", new Date("2022-05-03"),
      `De meeste mensen zijn na corona snel weer beter.
      Soms houden mensen langer dan 6 weken klachten na corona.
      Die klachten kunnen veel invloed hebben op de dingen die u elke dag doet.
      \n Deze klachten komen het meest voor na corona:
      \n- [moe zijn](https://www.thuisarts.nl/corona/ik-blijf-moe-na-corona)
      \n- [benauwd zijn of moeilijk ademen](https://www.thuisarts.nl/corona/ik-blijf-benauwd-of-moeilijk-ademen-na-corona)
      \n- [niet fit zijn](https://www.thuisarts.nl/corona/ik-heb-corona-gehad-en-voel-me-niet-fit)
      \n- [minder goed denken of onthouden](https://www.thuisarts.nl/corona/ik-kan-minder-goed-denken-of-onthouden-na-corona)
      \n- [minder ruiken of proeven](https://www.thuisarts.nl/corona/ik-blijf-minder-ruiken-of-proeven-na-corona)
      \n- [bang of somber zijn](https://www.thuisarts.nl/corona/ik-blijf-bang-of-somber-na-corona)
      \n U leest op de pagina's hierboven wat u zelf kunt doen als u 1 of meer van deze klachten heeft en van wie u hulpt kunt krijgen.
      Lees ook de tips over [weer aan het werk gaan na corona](https://www.thuisarts.nl/corona/ik-heb-corona-gehad-en-wil-weer-gaan-werken).
      \n *Bron: Nederlands Huisartsen Genootschap & Federatie van Medisch Specialisten*`),
  createNewsItem("Goede voeding", new Date("2022-05-02"),
      `Gezond eten is belangrijk, hiermee zorg je dat je lichaam goed functioneert en fit wordt of blijft.
      Daarnaast vermindert goede voeding het risico op bijvoorbeeld hart- en vaatziekten, diabetes en kanker.
      Met het oog op de zomer willen veel mensen graag in het voorjaar afvallen of bewuster gaan eten.
      Voeding is hier ook een belangrijk onderdeel van.
      \n Gezonde voeding zorgt ervoor dat je van alle voedingsstoffen voldoende binnen krijgt.
      Hieronder staan een aantal tips.
      Wilt u meer weten over gezonde voeding, klik dan op onderstaande link:
      [https://www.thuisarts.nl/gezond-leven/ik-wil-gezond-eten](https://www.thuisarts.nl/gezond-leven/ik-wil-gezond-eten).
      \n- Eet voldoende groente en fruit
      \n- Kies voor volkoren producten
      \n- Eet elke week peulvruchten
      \n- Kies voor gezonde vetten
      \n- Beperk zoutinname
      \n- Kies voor drinken zonder suiker en wees matig met alcohol`),
  createNewsItem("Griepseizoen", new Date("2021-10-09"),
      `Op dit moment heerst de griep weer. Het kan zijn dat u klachten krijgt van verkoudheid of hoesten.
      Deze klachten kunnen enkele weken aanhouden, maar gaan meestal vanzelf over. Antibiotica helpen niet tegen de griep.
      Antibiotica werken alleen tegen bacteriën, niet tegen virussen zoals het griepvirus.
      Bent u benauwd, heeft u meer dan 5 dagen hoge koorts of wordt u suf, maak dan een afspraak op het spreekuur van de huisarts.
      \n Iedereen met milde klachten die mogelijk duiden op het coronavirus wordt aangeraden een afspraak te maken om zich te laten testen bij de GGD, **bij klachten is een zelftest niet voldoende.**
      Voor het maken van een afspraak belt u met het landelijke afspraken nummer 0800-1202. Of online via Coronatest.nl; hiervoor heeft u uw DigiD nodig.
      \n Meer informatie kunt u vinden op:
      \n [https://www.thuisarts.nl/griep/ik-heb-griep](https://www.thuisarts.nl/griep/ik-heb-griep)
      `),
  createNewsItem("Griep- en pneumokkenvaccinaties", new Date("2021-10-08"),
      `Het is weer tijd voor de jaarlijkse **griepvaccinaties**.
       Op donderdag 14 en vrijdag 15 oktober geven wij de griepprik aan patiënten boven de 60 jaar of patiënten met een medische indicatie.
       Alle patiënten die hiervoor in aanmerking komen, hebben per post een uitnodiging ontvangen.
       Kunt u niet op de toegestuurde afspraak? Neem dan contact op met de assistente om een andere afspraak te maken.
       \n Patiënten die geboren zijn tussen 1948 en 1952 ontvangen dit jaar naast een uitnodiging voor de griepprik ook een uitnodiging voor de **pneumokokken vaccinatie**.
       Pneumokokken zijn bacteriën. Vooral ouderen kunnen er erg ziek van worden en longontsteking krijgen.
       Deze prik beschermt u tegen deze bacteriën.
       \n **Meer patiënten een griepprik**
       \n Sinds kort weten we dat patiënten die eerder niet in de risicocategorie vielen, ook erg ziek kunnen worden door het griepvirus.
       Zij kunnen ook een gratis griepprik krijgen. Valt u in deze groep, dan kunt u zelf bellen naar de assistente om een afspraak voor de griepprik te maken.
       \n Meer informatie hierover en over de groepen die hiervoor in aanmerking komen kunt u vinden op:
       \n [https://www.thuisarts.nl/nieuws/meer-mensen-griepprik](https://www.thuisarts.nl/nieuws/meer-mensen-griepprik)
       `),
  createNewsItem("Stoptober!", new Date("2021-10-01"),
      `Op 1 oktober is Stoptober begonnen: 28 dagen niet roken in oktober, samen met meer dan 50.000 andere rokers.
       Door in te schrijven op [Stoptober.nl](https://stoptober.nl/) krijg je hulp via de app Stoptober.
       Andere mensen die meedoen en stop-coaches helpen je dan om het stoppen met roken vol te houden. Kijk ook op Thuisarts.nl voor adviezen die je kunnen helpen als je gaat stoppen.
       \n Je vindt er manieren om te stoppen in een keuzetabel, een korte film en wat je kunt doen op moeilijke momenten.
       \n [https://www.thuisarts.nl/stoppen-met-roken/ik-wil-nu-stoppen-met-roken](https://www.thuisarts.nl/stoppen-met-roken/ik-wil-nu-stoppen-met-roken)`,
      new Photo(Stoptober, "Stoptober 2021")),
  createNewsItem("Prikpost Dorst weer geopend", new Date("2021-01-16"),
      `De prikpost in onze praktijk in Dorst is weer geopend.
       U kunt voor bloed prikken terecht op de woensdagen van 08.00 tot 11.00 uur.
       In verband met corona is dit uitsluitend op afspraak mogelijk.
       Dit kan bij Star-shl via [https://www.star-shl.nl/patient/afspraak-maken/](https://www.star-shl.nl/patient/afspraak-maken/).
       \n Mocht u hulp nodig hebben bij het maken van een afspraak kunt u contact opnemen met de praktijk,
       de assistentes willen u hier graag bij helpen.`),
  createNewsItem("De eikenprocessierups is er weer", new Date("2020-06-16"),
      `De brandharen van de eikenprocessierups kunnen klachten geven. Bijvoorbeeld jeuk of bultjes op de huid
      of oogklachten.
      \n- Blijf uit de buurt van eikenbomen met rupsen en rupsennesten.
      \n- Raak de rupsen niet aan en ga niet op de grond zitten.
      \n- Zorg dat uw hals, armen en benen bedekt zijn.
      \n- Leg ook uit aan uw kind om uit de buurt te blijven van eikenbomen met rupsen of (oude) nesten.
      \n\n Als u toch klachten heeft van de brandharen:
      \n- Was of spoel de huid goed met veel lauw water.
      \n- Plak de huid af met plakband en haal dit er weer af.
      \n- En wrijf of krab niet.
      \n- Bij veel jeuk kan een verkoelende zalf of crème met bijv. menthol helpen. U kunt dit zonder recept bij de drogist of apotheek kopen.
      \n\n Lees meer informatie op [Thuisarts.nl](https://www.thuisarts.nl/eikenprocessierups/ik-heb-klachten-door-eikenprocessierups)
      bij klachten door de eikenprocessierups.
      `),
  createNewsItem("Het Patiëntportaal", new Date("2020-06-16"),
      `Het is vanaf nu mogelijk om gebruik te maken van het patiëntportaal. U kunt hier bijvoorbeeld bekijken
      welke afspraken er gepland staan en welke medicijnen u gebruikt. In de toekomst zal het ook mogelijk worden om
      via het patiëntportaal inzicht te krijgen in uw medisch dossier.
      \n Meer te weten komen over het patiëntportaal en hoe u zich hiervoor aanmeldt? Ga dan naar het tabblad
       'Patiëntenportaal' of klik op onderstaande link.
      \n [Naar het portaal](/online-regelen)
      `),
  createNewsItem("Lancering nieuwe website", new Date("2020-06-16"),
      "Vanaf vandaag staat onze nieuwe website online.")
]
    .filter(newsItem => newsItem.date <= new Date())
    .sort((a, b) => b.date.getTime() - a.date.getTime());