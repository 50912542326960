import { FaqItem } from "../domain/faq-item";

const createFaqItem = (header: string, text: string) => {
  return new FaqItem(header, text);
}

export const faqItems = [
    createFaqItem("Ik heb een medische verklaring nodig, kan ik hiervoor bij de huisarts terecht?",
        `Een geneeskundige verklaring mag alleen worden afgegeven door een onafhankelijke arts.
        Deze arts kan een onafhankelijke beoordeling maken van de situatie.
        In veel gevallen is het verstandig de patiënt te verwijzen naar een arts die de criteria kent voor het toewijzen van de desbetreffende voorziening.
        Als dat nodig is, kan die arts, met de toestemming van de patiënt, extra informatie opvragen bij de patiënt, huisarts en/of andere behandelend artsen.
        \n Op deze website kunt u een officiële weigeringsbrief vinden: [https://www.knmg.nl/advies-richtlijnen/dossiers/geneeskundige-verklaring.htm](https://www.knmg.nl/advies-richtlijnen/dossiers/geneeskundige-verklaring.htm)`),
    createFaqItem("Kan ik bij mijn eigen huisarts terecht voor een rijbewijskeuring?",
        `De huisartsen in de praktijk doen geen rijbewijskeuringen bij eigen patiënten, zij verwijzen u door naar een onafhankelijke collega.
        Dit doen zij om de bestaande vertrouwensrelatie niet te verstoren.`),
    createFaqItem("Waarom stelt de doktersassistente zoveel vragen voordat ik een afspraak op het spreekuur mag maken?",
        `Wanneer u onze huisartsenpraktijk belt krijgt u de doktersassistente aan de telefoon.
        Zij vraagt naar de reden dat u belt en stelt een aantal vragen om uw hulpvraag duidelijk te krijgen.
        Dat heet triage.
        Met behulp van triage kan worden bereikt dat een patiënt de juiste zorg krijgt, op het juiste moment en bij de juiste hulpverlener (assistente, huisarts, praktijkondersteuner).
        Het doel is om de praktijk efficiënt te laten functioneren en de kwaliteit van zorg te handhaven en ondanks de toenemende drukte de wachttijden tot een minimum te beperken.
        \n Triage gebeurt op basis van zorgvuldig opgestelde protocollen.
        De assistenten hebben hiervoor de noodzakelijke cursussen gevolgd.
        Uiteraard doet de doktersassistente alles in samenspraak en overleg met uw eigen huisarts.
        De assistente is (net als uw huisarts) verplicht om vertrouwelijk met uw informatie en gegevens om te gaan.`),
    createFaqItem("Ik wil thuiszorg aanvragen. Waar kan ik terecht?",
        `Als u thuiszorg nodig heeft dan kunt u of een familielid dat zelf aanvragen, hiervoor is geen verwijzing nodig van de huisarts.
        U kunt rechtstreeks contact opnemen met een thuiszorgorganisatie, bv Thebe, Surplus, Groot Brabant of Actief Zorg.`),
    createFaqItem("Hoe kom ik aan een medicijnpaspoort?",
        `Dit kan u bij uw Apotheek aanvragen, zij hebben al uw medicatiegegevens.`),
    createFaqItem("Ik sta niet ingeschreven maar wil/moet wel door een huisarts gezien worden, kan dat?",
        `Als u niet ingeschreven staat in de buurt bij een eigen huisarts, kunt u bij ons als passant terecht.
        Bijvoorbeeld als u op vakantie of op familiebezoek bent.
        We schrijven u dan tijdelijk in, uw eigen huisarts krijgt hier geen bericht van.`)
]