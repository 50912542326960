import { Collapse } from "antd";
import React from "react";
import "./faq-page.scss";
import ReactMarkdown from "react-markdown";
import { faqItems } from "../../data/faq";

const { Panel } = Collapse;


export const FaqPage = () => (
    <div className="faq-page">
      <section className="content entry flex">
        <h2 className="has-line">
          Veelgestelde vragen
        </h2>
        <Collapse accordion className="faq">
          {
            faqItems.map((item, i) => <Panel key={`faq-item-${i}`} header={item.header}>
              <ReactMarkdown children={item.text}/>
            </Panel>)
          }
        </Collapse>
      </section>
    </div>
)