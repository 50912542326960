import React from "react";
import { NavLink } from "react-router-dom";
import PracticeBavel from "../../resources/img/bavel/bavel.jpg";
import PracticeDorst from "../../resources/img/dorst/dorst.jpg";
import BalieDorst from "../../resources/img/dorst/balie_dorst.jpg";
import "./about-page.scss";

export const AboutPage = () => (
    <div className="about-page">
      <section className="practice-intro entry">
        <div className="content flex">
          <h2 className="has-line">Praktijkinformatie</h2>
          <div className="practice-intro-content">
            <figure className="practice-intro-picture">
              <img src={BalieDorst}
                   className="lazy lazyloaded object-fit"
                   alt="Balie Dorst"
              />
            </figure>
            <div className="practice-intro-text">
              <h3>Openingstijden</h3>
              <p>Maandag t/m vrijdag van 8.00 tot 17.00 uur.</p>
              <p>
                Tussen 12.30-13.30 uur is de praktijk wegens lunchpauze gesloten en alleen telefonisch bereikbaar voor
                spoedgevallen.
              </p>
              <p>Buiten kantoortijden (van 17.00 tot 8.00 uur), in het weekend en tijdens officiële feestdagen is de
                praktijk gesloten en kunt u alleen voor spoedeisende zaken, na telefonisch overleg, terecht bij de
                huisartsenpost in Breda. De huisartsenpost Breda bevindt zich naast de afdeling spoedeisende hulp van
                het Amphia ziekenhuis, locatie Molengracht in Breda. Telefoonnummer: 076 5258500.</p>

              <h3>Afspraak maken</h3>
              <p>
                Dagelijks is er spreekuur, zowel ’s ochtends als ’s middags. Het spreekuur is altijd op afspraak.
                Voor het maken van een afspraak verzoeken wij u te bellen tussen 08.00 en 12.00 uur. Met uitzondering
                voor spoedafspraken, hiervoor kunt u altijd telefonisch   contact opnemen tussen 08.00-17.00 uur.
              </p>
              <p>
                Indien u om medische redenen niet in staat bent om naar de praktijk te komen kan in overleg met de
                assistente een visite afgesproken worden.
              </p>

              <h3>No-show tarief</h3>
              <p>
                Wanneer u een afspraak heeft gemaakt maar hier niet voor komt opdagen hanteren wij een 'no-show'
                tarief.
              </p>
              <p>
                <NavLink to="/no-show-beleid">Klik hier</NavLink> voor ons beleid omtrent het niet verschijnen op een
                gemaakte afspraak.
              </p>

              <h3>Telefonisch spreekuur</h3>
              <p>
                Dagelijks is er een telefonisch terugbel spreekuur, doorgaans tussen 12.00 en 14.00 uur. U kunt hiervoor
                een afspraak maken bij de assistente.
              </p>

              <h3>Ophalen medicijnen</h3>
              <p>
                Medicijnen van de Regenboog apotheek kunt u ophalen in onze praktijk in Dorst op werkdagen van 11.00 tot
                12.30 en van 13.30 tot 17.00
              </p>

              <h3>Prikpost Dorst</h3>
              <p>
                Voor bloedprikken kunt u in onze praktijk in Dorst terecht op de woensdagen van 08.00-11.00 uur.
                U dient hiervoor een afspraak te maken bij Star-shl via <a href={"https://www.star-shl.nl/patient/afspraak-maken/"} target="_blank" rel="noopener noreferrer">
                https://www.star-shl.nl/patient/afspraak-maken/</a>
              </p>
              <div className="buttons">
                <a href="#dorst" className="button blue full-mobile">Praktijk Dorst</a>
                <a href="#bavel" className="button orange full-mobile">Praktijk Bavel</a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="practice dorst" id="dorst">
        <div className="content flex entry-bottom">
          <h2 className="has-line">Praktijk Dorst</h2>
          <div className="practice-info">
            <div className="practice-info-content">
              <div className="practice-image">
                <figure>
                  <img src={PracticeDorst}
                       className="lazy lazyloaded object-fit"
                       alt="Praktijk Dorst"/>
                </figure>
              </div>
            </div>
            <div className="practice-info-aside">
              <div className="practice-info-address">
                <div className="quick-information-block-inner-item">
                  <h3 className="quick-information-block-inner-title">Adres gegevens</h3>
                  <span className="quick-information-block-inner-text">
                      Taxusstraat 49 <br/>
                      4849 AC, Dorst
                  </span>
                </div>
                <div className="quick-information-block-inner-item">
                  <h3 className="quick-information-block-inner-title">Telefonisch contact</h3>
                  <span className="quick-information-block-inner-text">
                      <a href="tel:0161412100">(0161) 41 21 00</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="practice bavel" id="bavel">
        <div className="content flex entry-bottom">
          <h2 className="has-line">Praktijk Bavel</h2>
          <div className="practice-info">
            <div className="practice-info-content">
              <div className="practice-image">
                <figure>
                  <img src={PracticeBavel}
                       className="lazy lazyloaded object-fit"
                       alt="Praktijk Bavel"/>
                </figure>
              </div>
            </div>
            <div className="practice-info-aside">
              <div className="practice-info-address">
                <div className="quick-information-block-inner-item">
                  <h3 className="quick-information-block-inner-title">Adres gegevens</h3>
                  <span className="quick-information-block-inner-text">
                      Deken Dr. Dirckxweg 2 <br/>
                      4854 AB, Bavel
                  </span>
                </div>
                <div className="quick-information-block-inner-item">
                  <h3 className="quick-information-block-inner-title">Telefonisch contact</h3>
                  <span className="quick-information-block-inner-text">
                      <a href="tel:0161412100">(0161) 41 21 00</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
);