import React from 'react';
import { NavLink } from 'react-router-dom';
import HeroImage from '../../resources/img/hero-image.jpg';
import './home-page.scss';

export const HomePage = () => (
    <div className="home-page">
      <section className="hero">
        <div className="hero-wrapper">
          <div className="hero-image">
            <img src={HeroImage}
                 className="lazy lazyloaded object-fit"
                 alt="Co van Beijsterveldt achter zijn bureau"/>
          </div>
          <div className="hero-content">
            <h1 className="hero-title">Huisartsenzorg van deze tijd</h1>
            <span className="hero-subtitle">Huisartsenpraktijk van Beijsterveldt</span>
          </div>
        </div>
      </section>

      <section className="content flex">
        <div className="quick-information">
          <div className="quick-information-content">
            <p className="quick-information-intro">
              Gezondheid is in hoge mate bepalend voor de kwaliteit van het leven. Regelmatig ontstaan er kleine of
              grote haperingen. Vaak is het tijdelijk, maar het kan ook blijvend zijn. <br/><br/>
              Een aantal problemen wordt (van)zelf opgelost, soms wil je advies of is er deskundige zorg en aandacht
              nodig. Wij bieden deze persoonlijke zorg in een prettige omgeving met vertrouwde mensen, die weten wie je
              bent en je advies geven volgens de laatste richtlijnen. We nemen er de tijd voor. Samen zoeken we naar de
              beste oplossing door gebruik te maken van nieuwe mogelijkheden en inzichten. <br/><br/>
              Huisartsenzorg van deze tijd.
            </p>
          </div>
          <div className="quick-information-block">
            <div className="quick-information-block-inner">
              <div className="quick-information-block-inner-item">
                <h3 className="quick-information-block-inner-title">Openingstijden</h3>
                <p className="quick-information-block-inner-text">Maandag t/m vrijdag</p>
                <p className="quick-information-block-inner-text">08.00 - 17.00</p>
                <p className="quick-information-block-inner-text">Tussen 12.30-13.30 uur is de praktijk wegens lunchpauze gesloten en alleen telefonisch bereikbaar voor spoedgevallen.</p>
              </div>
              <div className="quick-information-block-inner-item">
                <h3 className="quick-information-block-inner-title">Telefonisch contact</h3>
                <p className="quick-information-block-inner-text"><a href="tel:0161412100">(0161) 41 21 00</a></p>

                <p className="quick-information-block-inner-text">Keuzemenu:</p>
                <ul className="quick-information-block-inner-list">
                  <li  className="quick-information-block-inner-list-item text-indented">Optie 1: spoedgevallen / emergencies</li>
                  <li  className="quick-information-block-inner-list-item text-indented">Optie 2: herhaalrecepten</li>
                  <li  className="quick-information-block-inner-list-item text-indented">Optie 3: assistente</li>
                  <li  className="quick-information-block-inner-list-item text-indented">Optie 4: intercollegiaal overleg</li>
                </ul>
              </div>
              <div className="quick-information-block-inner-item">
                <h3 className="quick-information-block-inner-title">Buiten kantooruren</h3>
                <p className="quick-information-block-inner-title">Huisartsenpost Breda</p>
                <span className="quick-information-block-inner-text">
                                      <a href="tel:0765258500">(076) 525 8500</a>
                                    </span>
              </div>
              <NavLink to="/online-regelen" className="button orange">Patiëntenportaal</NavLink>
            </div>
          </div>
        </div>
      </section>

    </div>
);