import React from 'react';
import { Employee } from '../../domain/employee';
import './employee-slide.component.scss';

export const EmployeeSlideComponent = (employee: Employee) => (
  <div className="employees-slider-slide">
    <div className="employees-slider-slide-content">
      <h4>{employee.name}</h4>
      <span>{employee.functionTitle}</span>
    </div>
    <figure className="employees-slider-slide-image">
      <img src={employee.photo.url}
           className="lazy lazyloaded object-fit"
           alt={employee.photo.altText}/>
    </figure>
  </div>
);