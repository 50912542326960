import React from 'react';
import './complaints-page.scss';

export const ComplaintsPage = () => (
    <div className="complaints-page">
      <section className="content entry flex">
        <h2 className="has-line">
          Klachtenregeling
        </h2>

        <h3>Heeft u een klacht over uw huisarts of andere medewerker van de praktijk?</h3>

        <p>
          Wij doen ons uiterste best u goede zorg te leveren. Voor ons is het belangrijk dat u zich prettig voelt bij
          de zorg die u van ons krijgt. Toch kan het voorkomen dat het op sommige momenten anders verloopt dan u wenst,
          u ergens niet tevreden over bent of een minder prettige ervaring heeft gehad.
        </p>

        <p>
          Wanneer u dit met ons wilt bespreken, zouden wij dat erg op prijs stellen. Op de hoogte zijn van uw onvrede
          stelt ons in de gelegenheid het probleem proberen op te lossen en uw onvrede proberen weg te nemen. Hiervoor
          kunnen we u altijd een gesprek aanbieden met de betrokken persoon, ongeacht of dit nu de huisarts, assistente
          of praktijkondersteuner is.
        </p>

        <p>Uiteraard hopen wij dat een gesprek voldoende zal zijn.</p>

        <p>
          Als u het moeilijk vindt de klacht mondeling in te dienen, kunt u ook gebruik maken van het
          klachtenformulier. Deze is aan te vragen bij de assistente. Zodra de arts de klacht heeft gelezen, zal contact
          met u worden opgenomen om uw klacht verder te behandelen.
        </p>

        <p>
          Is dit lastig voor u of komt u er met ons niet uit, dan kunt u uw klacht bespreken met een onafhankelijke en
          onpartijdige klachtenfunctionaris van Stichting Klachten en Geschillen Eerstelijnszorg (SKGE). De
          klachtenfunctionaris kan proberen de klacht te bemiddelen. De klachtenfunctionaris kiest geen partij en heeft
          daarom geen oordeel. Alles wat u de klachtenfunctionaris vertelt, is vertrouwelijk.
        </p>

        <p>
          Voor meer informatie of het indienen van een klacht via de klachtenfunctionaris verwijzen wij u
          naar <a href="https://www.skge.nl">www.skge.nl</a> of vraag de assistente om een folder.
        </p>
      </section>
    </div>
)