import React, { Component } from 'react';
import Slider, { Settings } from 'react-slick';
import { EmployeeInformationComponent } from '../../components/employee-information/employee-information.component';
import { EmployeeSlideComponent } from '../../components/employee-slide/employee-slide.component';
import { Employee } from '../../domain/employee';
import HeroImage from '../../resources/img/hero-image.jpg';
import './employees-page.scss';

class EmployeesPageProps {
  constructor(readonly employees: Array<Employee>) {
  }
}

class EmployeesPageState {
  constructor(readonly selectedEmployeeIndex: number,
              readonly sliderSettings: Settings) {
  }
}

export class EmployeesPage extends Component<EmployeesPageProps, EmployeesPageState> {

  constructor(props: EmployeesPageProps) {
    super(props);
    this.state = {
      selectedEmployeeIndex: 0,
      sliderSettings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        initialSlide: 0,
        afterChange: (index: number) => {
          this.setState({ selectedEmployeeIndex: index });
        },
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  }

  render() {
    return (
        <div className="employees-page">
          <section className="hero">
            <div className="hero-wrapper">
              <div className="hero-image">
                <img src={HeroImage}
                     className="lazy lazyloaded object-fit"
                     alt="Co van Beijsterveldt achter zijn bureau"/>
              </div>
            </div>
          </section>

          <section className="employees-intro">
            <div className="content flex">
              <div className="employees-intro-wrapper">
                <div className="employees-intro-header">
                  <h2 className="hero-title has-line">Onze medewerkers</h2>
                </div>
                <div className="employees-intro-content">
                  <p>
                    Gezondheid is in hoge mate bepalend voor de kwaliteit van het leven. Regelmatig ontstaan er kleine
                    of
                    grote haperingen. Vaak is het tijdelijk, maar het kan ook blijvend zijn.
                  </p>
                  <p>
                    Een aantal problemen wordt (van)zelf opgelost, soms wil je advies of is er deskundige zorg en
                    aandacht
                    nodig. Wij bieden deze persoonlijke zorg in een prettige omgeving met vertrouwde mensen, die weten
                    wie
                    je bent en je advies geven volgens de laatste richtlijnen. We nemen er de tijd voor. Samen zoeken we
                    naar de beste oplossing door gebruik te maken van nieuwe mogelijkheden en inzichten.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="employees">
            <div className="employees-slider-wrapper">
              <div className="latest-news-title">
                <h2 className="has-line">Medewerkers</h2>
              </div>

              <Slider {...this.state.sliderSettings} className="employees-slider">
                {this.props.employees.map((employee, index) =>
                    <EmployeeSlideComponent key={index} {...employee}/>
                )}
              </Slider>
            </div>

            <div className="content">
              <EmployeeInformationComponent {...this.props.employees[this.state.selectedEmployeeIndex]} />
            </div>
          </section>
        </div>
    );
  }
}