import React from 'react';

const links = [
    "www.thuisarts.nl",
    "www.moetiknaardedokter.nl",
    "www.amphia.nl",
    "www.bevolkingsonderzoeknederland.nl",
    "www.ggdwb.nl",
    "www.denederlandseggz.nl",
    "www.huisartsenpostenwestbrabant.nl",
    "www.star-shl.nl/patient/"
]


export const UsefulLinksPage = () => (
    <div className="useful-links-page">
      <section className="content entry flex">
        <h2 className="has-line">
          Handige links
        </h2>

        <p className="useful-links">
          <ul>
            {
              links.map(url => <li><a href={`https://${url}`}>{url}</a></li>)
            }
          </ul>
        </p>
      </section>
    </div>
)