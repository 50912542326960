import classNames from 'classnames';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../resources/img/logo/logo-van-beijsterveldt.svg';
import './header.scss';

export class Header extends Component {
  state = {
    open: false
  };

  toggleMenu = () => this.setState({ open: !this.state.open });
  closeMenu = () => this.setState({ open: false });

  render() {
    return (<header className={classNames('header', { 'menu-open': this.state.open })}>
          <NavLink to="/">
            <div className="header-logo">
              <img src={Logo} alt="Logo huisartsenpraktijk van Beijsterveldt"/>
            </div>
          </NavLink>
          <nav className="header-nav">
            <ul className="header-nav-list">
              <li className="header-nav-item"><NavLink to="/" onClick={this.closeMenu}>Home</NavLink></li>
              <li className="header-nav-item">
                <NavLink to="/praktijkinformatie" onClick={this.closeMenu}>Praktijkinformatie</NavLink>
              </li>
              <li className="header-nav-item">
                <NavLink to="/online-regelen" onClick={this.closeMenu}>Patiëntportaal</NavLink>
              </li>
              <li className="header-nav-item"><NavLink to="/nieuws" onClick={this.closeMenu}>Nieuws</NavLink></li>
              <li className="header-nav-item"><NavLink to="/veelgestelde-vragen" onClick={this.closeMenu}>Veelgestelde vragen</NavLink></li>
              <li className="header-nav-item"><NavLink to="/handige-links" onClick={this.closeMenu}>Handige links</NavLink></li>
              <li className="header-nav-item">
                <NavLink to="/medewerkers" onClick={this.closeMenu}>Medewerkers</NavLink>
              </li>
              <li className="header-nav-item">
                <NavLink to="/inschrijven" onClick={this.closeMenu}>Inschrijven</NavLink>
              </li>
            </ul>
          </nav>
          <div className="header-meta">
            <a href="tel:0161412100" className="header-meta-tel">0161-412100</a>
          </div>
          <button className="header-menu-toggle js-menu-toggle hide-from-large" title="Menu" aria-haspopup="true"
                  aria-controls="menu">
        <span className="menu" onClick={this.toggleMenu}>
            <span className="menu-icon">
            </span>
        </span>
          </button>
        </header>
    )
  }
}