import React from 'react';

export const SignInPage = () => (
    <div className="signin-page">
      <section className="content entry flex">
        <h2 className="has-line">
          Inschrijven
        </h2>

        <p>
          Wanneer u woonachtig bent in Dorst, Bavel of Molenschot is het mogelijk om in onze praktijk ingeschreven te
          worden. U kunt hiervoor op werkdagen tussen 8.00-17.00 uur contact opnemen met de praktijk. Indien gewenst is
          er een mogelijkheid voor een kennismakingsgesprek.
        </p>
      </section>
    </div>
);
