import React from 'react';
import './no-show-page.scss';

export const NoShowPage = () => (
    <div className="no-show-page">
      <section className="content entry flex">
        <h2 className="has-line">
          No show beleid
        </h2>

        <p>
          Helaas blijkt het in toenemende mate te gebeuren dat patiënten een afspraak maken maar niet op de afgesproken
          tijd verschijnen. Hiermee gaat onnodig spreekuur tijd verloren. Deze verloren tijd gaat met name ten koste van
          andere patiënten die ook graag een afspraak willen maken.
        </p>

        <p>
          Als u de afspraak niet door kunt laten gaan, vragen wij u (het liefst 24 uur) van tevoren de afspraak af te
          zeggen. Bij niet verschijnen zonder bericht krijgt u in uw medisch dossier een aantekening NVZB (Niet
          Verschenen Zonder Bericht).
        </p>

        <p>
          Wanneer u in de toekomst vaker niet verschijnt op een afspraak, een afspraak vergeet of niet tijdig afzegt,
          kunnen wij een no show tarief in rekening brengen. Dit geldt voor het niet nakomen van afspraken bij de
          huisarts, praktijkondersteuner en assistente.
        </p>

        <p>
          Het tarief dat wij hanteren voor een no show is 10 euro voor een enkel consult van 10 minuten. Voor een langer
          consult rekenen wij 20 euro. Consulten bij praktijkondersteuners zijn altijd langere consulten.
        </p>

        <p>
          Omdat er geen zorg is verleend, wordt deze rekening niet door uw zorgverzekeraar vergoed en dient u deze nota
          zelf te betalen.
        </p>

        <p>
          Wij hopen dit tarief zelden of nooit in rekening te hoeven brengen. Ons primaire doel is het terugdringen van
          het aantal personen dat niet op de afspraak verschijnt. Zo kunnen we op die tijd een andere patiënt helpen en
          de wachttijden voor iedereen korter houden.
        </p>

        <p>
          Bedankt voor uw begrip.
        </p>
      </section>
    </div>
);