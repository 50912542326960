import React from 'react';
import ReactMarkdown from 'react-markdown';
import { NewsItem } from '../../domain/news-item';
import './news-item.component.scss';

export const NewsItemComponent = (newsItem: NewsItem) => (
    <li className="news-item">
      <div className="news-item-content">
        <div className="news-item-content-header">
          <h4>{newsItem.title}</h4>
          <span className="news-item-content-date">{newsItem.date.toLocaleDateString()}</span>
        </div>
        {newsItem.picture && <div className="news-item-content-image">
          <img src={newsItem.picture.url}
               className="lazy lazyloaded"
               alt={newsItem.picture.altText}/>
        </div>}
        <div className="news-item-content-text">
          <ReactMarkdown children={newsItem.content}/>
        </div>
      </div>
    </li>
);