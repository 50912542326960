import React from 'react';
import './App.scss';
import { Footer } from './components/footer/footer';
import { Header } from './components/header/header';
import ScrollToTop from './functions/scroll-to-top';
import Routes from './routes';

const App = () => (
    <div className="page-wrapper">
      <Header/>
      <div className="main">
        <ScrollToTop/>
        <Routes/>
      </div>
      <Footer/>
    </div>
);

export default App;
