import React from 'react';
import './privacy-page.scss';

export const PrivacyPage = () => (
    <div className="privacy-page">
      <section className="content entry-top flex">
        <h2 className="has-line">
          AVG Privacywetgeving
        </h2>
        <p>
          Sinds 25 mei 2018 is de Algemene verordening gegevensbescherming (AVG) van toepassing. Dat betekent dat er
          vanaf die datum dezelfde privacywetgeving geldt in de hele Europese Unie (EU).
        </p>

        <p>
          Naar aanleiding van de AVG wet willen wij u informeren dat het gezien ieder zijn privacy van belang is, dat
          er geen uitslagen, medische vragen of afspraken gemaakt worden door andere mensen als die het betreft. Tevens
          komt erbij dat de benodigde informatie niet geheel voor handen is, wanneer er iemand anders contact met de
          praktijk opneemt dan de betrokken persoon.
        </p>

        <p>Wij willen ons aan deze voorschriften houden om ieders privacy te waarborgen en goede zorg te leveren.</p>

        <p>Hartelijk dank voor uw medewerking</p>
      </section>
      <section className="content entry flex">
        <h3>Privacyreglement van onze praktijk</h3>
        <p>Uw persoonsgegevens en uw privacy in onze huisartsenpraktijk</p>

        <h3>Algemeen</h3>
        <p>
          De Algemene Verordening Gegevensbescherming, verder genoemd de AVG, is de nieuwe wet ter bescherming van
          privacy en persoonsgegevens. Op grond van deze wet heeft een organisatie die met persoonsgegevens werkt
          bepaalde plichten en heeft degene van wie de gegevens zijn bepaalde rechten. Naast deze algemene wet gelden
          specifieke regels voor de privacy in de gezondheidszorg. Deze regels staan onder andere vermeld in de Wet
          Geneeskundige Behandeling Overeenkomst (WGBO). Dit privacyreglement is bedoeld om u te informeren over uw
          rechten en onze plichten die gelden op grond van de AVG en de WGBO.
        </p>

        <h3>Huisartsenpraktijk</h3>
        <p>
          In onze huisartsenpraktijk kunnen diverse persoonsgegevens van u verwerkt worden. Dit is noodzakelijk om u
          medisch goed te kunnen behandelen en nodig voor het financieel afhandelen van de behandeling. Daarnaast kan
          verwerking noodzakelijk zijn voor, bijvoorbeeld, de bestrijding van ernstig gevaar voor uw gezondheid of ter
          voldoening aan een wettelijke verplichting (bijvoorbeeld het verplicht melden van een besmettelijke ziekte op
          grond van de Wet Publieke Gezondheid).
        </p>

        <h3>De plichten van de huisartsenpraktijk</h3>
        <p>
          Huisartsenpraktijk van Beijsterveldt is volgens de AVG de verantwoordelijke voor de verwerking van
          persoonsgegevens die in de praktijk plaatsvindt. Aan de plichten die daaruit voortkomen, voldoet de praktijk
          als volgt:
        </p>

        <div className="content-list">
          <span className="list-header">Uw gegevens worden voor specifieke doeleinden verzameld:</span>
          <ul>
            <li>voor zorgverlening</li>
            <li>voor doelmatig beheer en beleid</li>
            <li>voor ondersteuning van wetenschappelijk onderzoek, onderwijs en voorlichting</li>
          </ul>
        </div>

        <p>
          Er vindt in beginsel geen verwerking plaats voor andere doeleinden. U wordt op de hoogte gesteld van het
          feit
          dat er persoonsgegevens van u verwerkt worden. Dit kan gebeuren door uw zorgverlener, maar ook via een
          folder
          of via onze website.
          Alle medewerkers binnen Huisartsenpraktijk van Beijsterveldt hebben zich verplicht om vertrouwelijk om te
          gaan
          met uw persoonsgegevens.
          Uw persoonsgegevens worden goed beveiligd tegen onbevoegde toegang.
          Uw persoonsgegevens worden niet langer bewaard dan noodzakelijk is voor goede zorgverlening.
        </p>

        <p>
          Voor medische gegevens is deze bewaartermijn in principe 15 jaar (vanaf de laatste behandeling), tenzij langer
          bewaren noodzakelijk is, bijvoorbeeld voor de gezondheid van uzelf of van uw kinderen. Dit is ter beoordeling
          van de behandelaar.
        </p>

        <h3>Uw rechten als betrokkene</h3>
        <div className="content-list">
          <span className="list-header">U heeft de volgende rechten:</span>
          <ul>
            <li>Het recht om te weten of en welke persoonsgegevens van u verwerkt worden</li>
            <li>Het recht op inzage en afschrift van die gegevens (voor zover de privacy van een ander daardoor niet
              wordt geschaad)
            </li>
            <li>Het recht op correctie, aanvulling of verwijdering van gegevens indien dat nodig mocht zijn</li>
            <li>Het recht om (gedeeltelijke) vernietiging van uw medische gegevens te vragen. Hieraan kan alleen
              tegemoet
              worden gekomen als het bewaren van de gegevens voor een ander niet van aanmerkelijk belang is en de
              gegevens
              op grond van een wettelijke regeling niet bewaard moeten blijven
            </li>
            <li>Het recht op het toevoegen van een eigen verklaring (van medische aard) aan uw dossier</li>
            <li>Het recht om u in bepaalde gevallen tegen de verwerking van uw gegevens te verzetten</li>
          </ul>
        </div>

        <p>
          Als u gebruik wilt maken van uw rechten, dan kunt u dit middels een aanvraagformulier kenbaar maken aan
          Huisartsenpraktijk van Beijsterveldt. Dit aanvraagformulier kunt u afhalen bij Huisartsenpraktijk van
          Beijsterveldt. Uw belangen kunnen ook behartigd worden door een vertegenwoordiger (zoals een schriftelijk
          gemachtigde, of uw curator of mentor). Dit dient vooraf aangegeven te worden d.m.v. een getekend
          toestemmingformulier van de patiënt en gemachtigde.
        </p>

        <h3>Toelichting op het aanvraagformulier</h3>
        <p>
          U moet er rekening mee houden dat medische gegevens ingevolge de wet in principe maximaal vijftien jaar
          bewaard worden. U helpt ons met het opzoeken van uw dossier en het beschermen van uw privacy als u het
          formulier zo volledig mogelijk invult. De door u ingevulde gegevens worden door ons strikt vertrouwelijk
          behandeld. Het afschrift van de gegevens dient persoonlijk te worden afgehaald.
        </p>

        <h3>Gegevens patiënt</h3>
        <p>
          Hierbij vermeldt u de gegevens van de persoon over wie het medisch dossier gaat. De Wet Geneeskundige
          Overeenkomst (WBGO) beschouwt de patiënt als meerderjarig vanaf 16 jaar. Jongeren vanaf 16 jaar die
          inzage/afschrift van hun medisch dossier willen, moeten zelf de aanvraag indienen. Indien de patiënt niet meer
          in leven is, is het verstrekken van de medische gegevens toegestaan indien verondersteld kan worden dat de
          overledene hiertegen geen bezwaar zou hebben gehad of er sprake is van zwaarwegende belangen om de zwijgplicht
          van de zorgverlener te doorbreken. Deze beslissing ligt bij de zorgverlener.
        </p>

        <h3>Verstrekking van uw persoonsgegevens aan derden</h3>
        <p>
          De medewerkers van Huisartsenpraktijk van Beijsterveldt hebben de verplichting vertrouwelijk met uw
          persoonsgegevens om te gaan. Dit houdt bijvoorbeeld in dat de zorgverlener voor verstrekking van uw
          persoonsgegevens uw uitdrukkelijke toestemming nodig heeft. Op deze regel bestaan echter enkele
          uitzonderingen. Op grond van een wettelijk voorschrift kan de zwijgplicht van de zorgverlener doorbroken
          worden, maar ook wanneer gevreesd moet worden voor een ernstig gevaar voor uw gezondheid of die van een derde.
          Bovendien kunnen vastgelegde gegevens, indien noodzakelijk mondeling, schriftelijk of digitaal uitgewisseld
          worden met andere zorgverleners (bijvoorbeeld de apotheker die een recept verwerkt en zodoende gegevens
          verstrekt krijgt van de huisarts).
        </p>

        <h3>Uitwisseling gegevens</h3>
        <p>
          Huisartsenpraktijk van Beijsterveldt wisselt, nadat u hiervoor gericht toestemming heeft gegeven, via het
          Landelijk schakelpunt, relevante medische gegevens veilig en betrouwbaar met de huisartsenpost (HAP). Bent u
          ’s avonds of in het weekend op de HAP geweest, dan deelt die op zijn beurt een waarneembericht met de
          huisartsenpraktijk. Zo weet de huisarts precies met welke klachten u op de HAP bent geweest en wat er naar
          aanleiding daarvan is ondernomen.
        </p>

        <p>
          Ook kunnen er medicatiegegevens gedeeld worden met uw apotheek en uw behandelend medisch specialisten.
          Daarbij gaat het om de medicatie die de huisarts aan u heeft voorgeschreven, maar ook om eventuele
          intoleranties, contra-indicaties en allergieën (ICA-gegevens). Daar kunnen andere voorschrijvers en
          verstrekkers van medicatie rekening mee houden. Zo dragen wij als huisartsenpraktijk bij aan
          medicatieveiligheid.
        </p>

        <h3>Overdracht van uw dossier</h3>
        <p>
          Als u een nieuwe huisarts kiest, is het belangrijk dat uw nieuwe huisarts op de hoogte is van uw medische
          geschiedenis. Uw medische geschiedenis staat in uw patiëntendossier. Het is gebruikelijk dat uw oude huisarts
          uw medisch dossier via een beveiligde website genaamd ZorgMail File Transfer, overdraagt aan uw nieuwe
          huisarts. U kunt het originele dossier niet meekrijgen. Wel heeft u altijd recht op inzage in uw dossier en op
          een kopie van uw dossier.
        </p>
      </section>
    </div>
)