import { Photo } from './photo';

export class NewsItem {

  constructor(readonly title: string,
              readonly date: Date,
              readonly content: string,
              readonly picture?: Photo) {
  }

}