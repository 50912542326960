import React from 'react';
import { Route, Switch } from 'react-router';
import { employees } from './data/employees';
import { newsItems } from './data/news';
import { AboutPage } from './pages/about/about-page';
import { ComplaintsPage } from './pages/complaints/complaints-page';
import { EmployeesPage } from './pages/employees/employees-page';
import { ErrorPage } from './pages/error/error';
import { FaqPage } from "./pages/faq/faq-page";
import { HomePage } from './pages/home/home-page';
import { NewsPage } from './pages/news/news-page';
import { NoShowPage } from './pages/no-show/no-show-page';
import { PatientPortal } from './pages/patient-portal/patient-portal';
import { PrivacyPage } from './pages/privacy/privacy-page';
import { SignInPage } from './pages/signin/sign-in-page';
import { UsefulLinksPage } from "./pages/useful-links/useful-links-page";

const Routes = () => (
    <Switch>
      <Route exact path="/" component={HomePage}/>
      <Route path="/praktijkinformatie" component={AboutPage}/>
      <Route path="/online-regelen" component={PatientPortal}/>
      <Route path="/nieuws" render={() => <NewsPage newsItems={newsItems}/>}/>
      <Route path="/veelgestelde-vragen" component={FaqPage}/>
      <Route path="/handige-links" component={UsefulLinksPage}/>
      <Route path="/medewerkers" render={() => <EmployeesPage employees={employees}/>}/>
      <Route path="/klachtenregeling" component={ComplaintsPage}/>
      <Route path="/privacyreglement" component={PrivacyPage}/>
      <Route path="/no-show-beleid" component={NoShowPage} />
      <Route path="/inschrijven" component={SignInPage} />
      <Route component={ErrorPage}/>
    </Switch>
);

export default Routes;