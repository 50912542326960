import React from 'react';
import { Employee } from '../../domain/employee';
import './employee-information.component.scss';

export const EmployeeInformationComponent = (employee: Employee) => (
    <div className="employee-information">
      <div className="employee-information-image">
        <img src={employee.photo.url}
             className="lazy lazyloaded object-fit"
             alt={employee.photo.altText}
        />
      </div>
      <div className="employee-information-content">
        <h4>{employee.name}</h4>
        <span>{employee.functionTitle}</span>
        {employee.description.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        {employee.workingHours && <p>{employee.workingHours}</p>}
      </div>
    </div>
);