import React from 'react';
import './patient-portal.scss';

import { Collapse } from 'antd';

const { Panel } = Collapse;

export const PatientPortal = () => (
    <div className="patient-portal-page">
      <section className="content entry flex">
        <h2 className="has-line">
          Patiëntportaal
        </h2>
        <p>
          Via het patiëntportaal kunt u snel én makkelijk contact zoeken met uw huisarts. Het patiëntportaal is een
          beveiligde elektronische omgeving. Dat maakt het mogelijk via Patiëntportaal ook medische en persoonlijke
          vragen te stellen. Het enige wat u ervoor nodig hebt is een computer of een laptop. Patiëntportaal is ook te
          bereiken via de Uw Zorg Online App.
        </p>
        <p>
          Het is belangrijk om te weten dat de aanmelding persoonlijk is. Elk gezinslid heeft een eigen e-mailadres
          nodig, omdat aan uw e-mailadres uw eigen dossier gekoppeld wordt.
        </p>
        <a href="https://vanbeijsterveldt.uwzorgonline.nl" target="_blank" rel="noopener noreferrer"
           className="button orange full-mobile">
          Naar het portaal</a>
      </section>
      <section className="content flex entry-bottom">
        <h2 className="has-line">Uitleg</h2>
        <Collapse accordion className="pp-faq">
          <Panel key={'1'} header={'Wat kan ik met het patiëntportaal?'}>
            <p>
              <ul>
                <li>Medicatie overzicht inzien</li>
                <li>Herhaalrecepten aanvragen</li>
                <li>Vragen stellen door middel van e-Consult</li>
                <li>e-Consult overzicht inzien</li>
                <li>Afspraken overzicht inzien</li>
              </ul>
            </p>
            <section>
              <h3>Medicatie overzicht inzien en herhaalrecepten bestellen</h3>
              <p>
                U ziet in één oogopslag welke medicijnen u gebruikt of in het laatste jaar gebruikt heeft.
                U kunt aanvinken voor welke medicijnen u een herhaalrecept wilt aanvragen.
              </p>
            </section>
            <section>
              <h3>Vragen stellen door middel van e-Consult</h3>
              <p>
                Hebt u een vraag over een bestaande klacht of aandoening, dan is e-consult een handig middel.
                U kunt bijvoorbeeld vertellen hoe de verdere genezing is gegaan.
                Ook als u een laboratoriumuitslag wilt weten, kunt u de informatie via een e-consult opvragen.
                Bij een nieuwe vraag of klacht werkt e-consult minder goed.
                In dat geval kunt u beter een afspraak maken in de praktijk.
              </p>
            </section>
            <section>
              <h3>Toekomst</h3>
              <p>
                We verwachten dat u in de toekomst ook in het patiëntportaal uw eigen medische gegevens kan bekijken
                en het verslag lezen van uw laatste bezoek aan de huisarts.
              </p>
            </section>
          </Panel>
          <Panel key={'2'} header={'Aanmelden bij het patiëntportaal'}>
            <p>
              U wordt geregistreerd met uw e-mailadres.
              Hierna kunt u via uw e-mailadres inloggen wanneer u maar wilt.
              Op dit e-mailadres krijgt u, elke keer dat u bij het patiëntportaal inlogt, een sessiecode toegestuurd.
              Met deze sessiecode kunt u vervolgens de inloggen en contact maken met uw huisarts.
            </p>
            <section>
              <h3>Minderjarige kinderen aanmelden bij Patiëntportaal</h3>
              <p>
                In 1995 is de Wet op de Geneeskundige Behandelings Overeenkomst ingegaan (de WGBO).
                Deze wet garandeert de rechten van cliënten en patiënten ten opzichte van artsen en therapeuten.
                In deze wet staat ook wat de rechten zijn van kinderen.
                Deze gelden ook bij Patiëntportaal.
              </p>
            </section>
            <section>
              <h4>Kinderen tot 12 jaar</h4>
              <p>
                Kinderen tot 12 jaar beslissen nog niets zelf.
                De ouders of de voogd beslissen voor hen.
                Aan jonge kinderen moet wel op een begrijpelijke manier worden verteld wat er met hen gaat gebeuren.
                Voor kinderen tot 12 jaar kunnen de ouders een e-mailadres voor het patiëntportaal beheren.
              </p>
            </section>
            <section>
              <h4>Kinderen van 12 tot 16 jaar</h4>
              <p>
                Zowel de ouders als het kind moeten toestemming geven.
                De mening van het kind is in principe doorslaggevend.
                Ook voor het patiëntportaal geldt dat zowel de ouders als het kind het recht hebben om het dossier
                in te zien.
                Hiervoor kunnen ze gebruik maken van hetzelfde e-mailadres.
              </p>
            </section>
            <section>
              <h4>Kinderen vanaf 16 jaar</h4>
              <p>
                Kinderen vanaf 16 jaar zijn beslissingsbevoegd.
                Dat betekent dat ze zelf mogen beslissen welke medische behandeling ze willen of juist niet willen.
                De ouders krijgen alleen medische informatie over hun kind, als het kind daar toestemming voor geeft.
              </p>
            </section>
          </Panel>
        </Collapse>
      </section>
    </div>
);