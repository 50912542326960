import React from 'react';
import './news-page.scss';
import { NewsItemComponent } from '../../components/news-item/news-item.component';
import { NewsItem } from '../../domain/news-item';

class NewsPageProps {
  constructor(readonly newsItems: Array<NewsItem>) {
  }
}

export const NewsPage = (newsPageProps: NewsPageProps) => (
    <div className="news-page">
      <section className="content entry flex">
        <h2 className="has-line">
          Nieuws
        </h2>
        <div className="news-items">
          <ul className="news-items-list">
            {newsPageProps.newsItems.map((newsItem, index) =>
              <NewsItemComponent key={index} {...newsItem}/>
            )}
          </ul>
        </div>
      </section>
    </div>
);